<template>
  <div class="relative error-bg h-svh">
    <slot/>    
  </div>
</template>


<style>
.error-bg{
    background: url('/img/city-build.png') no-repeat bottom center;
}
</style>